.App {
	position: relative;
	height: 100vh;
	background-color: #ffffff;

	header {
		display: flex;
		width: 100%;
		box-sizing: border-box;

		.brand {
			color: #ffb14d;
			display: block;
			margin: 25px;
			font-size: 1.3rem;
			font-weight: bolder;
			text-decoration: none;
			cursor: pointer;

			span {
				vertical-align: super;
				margin-left: 10px;
			}
		}

		.center {
			flex-grow: 1;
		}
		.right {
			align-self: center;

			.profileBtn {
				height: 40px;
				margin: 15px;
			}
		}
	}

	footer {
		width: 100%;
		height: 50px;
		background-color: #3c404d;
		color: white;
		text-align: center;
	}

	ul {
		text-align: center;
		li {
			display: inline-block;
		}
	}
}

.user-info {
	padding: 10px;
}
