.Member {
	align-items: center;
	justify-content: center;
	display: flex;
	position: fixed;

	.card {
		background: white;
		border-radius: 30px;
		display: flex;
		flex-direction: column;
		width: 80%;
		min-width: 300px;
		max-width: 500px;
		max-height: 90vh;
		padding: 25px;
		box-sizing: border-box;

		.title {
			margin: 0;
		}

		.table-wrapper {
			overflow-y: auto;
			margin: 0 10px;
		}
	}
}
