@import url(//fonts.googleapis.com/earlyaccess/notosanskr.css);

body {
	margin: 0;
	//font-family: 'NanumSquare', sans-serif !important;
	//font-weight: bold;
	//font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	font-family: 'Noto Sans KR', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	background-color: white;
}

p {
	margin: 0;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.popup {
	height: 100vh;
	width: 100vw;
	position: fixed;
	z-index: 10;
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	backdrop-filter: blur(1px);

	background-color: #000a;
	vertical-align: middle;
	animation-duration: 0.3s;
	animation-name: popup-background;

	@keyframes popup-background {
		from {
			background-color: #0000;
			backdrop-filter: blur(0px);
		}
		to {
			background-color: #000a;
			backdrop-filter: blur(1px);
		}
	}
}

.tab-page {
	margin: 16px;
}

table {
	font-weight: bold;
	width: 100%;
	border-spacing: 0px;
	color: rgb(81, 81, 81);

	td {
		padding: 10px;
		white-space: nowrap;
	}
	thead {
	}
	tbody {
		background-color: rgb(243, 243, 243);

		td {
			font-size: 0.875rem;
		}

		--radius: 12px;
		--padding: 10px;
		tr {
			td {
				padding: calc(var(--padding) / 2);

				&:first-child {
					padding-left: calc(var(--padding));
				}

				&:last-child {
					padding-right: calc(var(--padding));
				}
			}
			&:first-child {
				td {
					padding-top: calc(var(--padding));
					&:first-child {
						border-top-left-radius: var(--radius);
					}
					&:last-child {
						border-top-right-radius: var(--radius);
					}
				}
			}
			&:last-child {
				td {
					padding-bottom: calc(var(--padding));
					&:first-child {
						border-bottom-left-radius: var(--radius);
					}
					&:last-child {
						border-bottom-right-radius: var(--radius);
					}
				}
			}
		}
	}
	tfoot {
		& > *:last-child {
			td {
				border: 0;
			}
		}
	}
}

ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

.red {
	color: red;
}

.green {
	color: green;
}
