.Receipt {
	color: '#fff';
	.card {
		background: white;
		border-radius: 30px;
		display: flex;
		flex-direction: column;
		width: 80%;
		min-width: 300px;
		max-width: 500px;
		max-height: 90vh;
		padding: 25px;
		box-sizing: border-box;

		@media (max-width: 500px) {
			padding: 15px;
			width: 100%;
		}
		.top {
			margin-top: 10px;
			margin-left: 10px;
			margin-right: 10px;
			.title {
				font-size: 1.7rem;
				font-weight: bolder;
			}
			.date {
				font-size: 0.75rem;
			}
		}

		table {
			td {
				&:nth-child(1) {
					text-align: left;
				}
				&:nth-child(2) {
					text-align: center;
					padding-left: 3px;
					padding-right: 3px;
					.person {
						.MuiBadge-badge {
							transform: scale(0.8) translate(50%, -33%);
						}
					}
				}
				&:nth-child(3) {
					text-align: left;
				}
				&:nth-child(4) {
					text-align: left;
				}
			}
		}

		.payer-table {
			width: 100%;
			th,
			td {
				& > p {
					width: 100%;
				}

				&:nth-child(1) {
					width: 120px;
					text-align: left;
				}
				&:nth-child(2) {
					text-align: left;
				}
			}
		}

		.space {
			margin: auto;
		}

		.content {
			flex-grow: 1;
			overflow-y: auto;
		}

		.actions {
			display: flex;
		}
		.opacity {
			opacity: 0.5;
		}
	}
}
