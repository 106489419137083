.Groups {
	padding: 20px;
	overflow-y: auto;
	.table-wrapper {
		overflow-y: auto;
		table {
			tbody {
				tr {
					cursor: pointer;
					transition-duration: 0.1s;
					&:hover {
						background: #bdbdbd;
					}
					&:active {
						background: #ababab;
					}
				}
			}
		}
	}
}
