.Group {
	position: relative;
	display: flex;
	flex-direction: column;
	background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 100px, #ffb14d 100px);
	width: 100%;
	min-height: 100vh;
	color: black;

	@media (max-width: 500px) {
		background: white;
	}

	.area {
		display: flex;
		width: 100%;
		margin-bottom: 30px;
		z-index: 1;
		@media (max-width: 500px) {
			margin-bottom: 0;
		}

		section {
			width: 700px;
			margin: 10px auto;
			padding: 30px;
			background-color: #ffffff;
			box-shadow: 0 0 30px -10px;
			border-radius: 30px;

			@media (max-width: 900px) {
				width: 400px;
			}

			@media (max-width: 500px) {
				width: 100%;
				margin: 0;
				padding: 10px;
				box-shadow: 0 0 0 0;
				border-radius: 0;
			}

			.top {
				display: flex;
				flex-direction: column;
				@media (max-width: 500px) {
					margin-left: 15px;
					margin-right: 15px;
				}
				.row {
					display: flex;
					justify-content: space-between;
				}
			}

			.group-title {
				font-size: 1.8rem;
				font-weight: bolder;
				flex-grow: 1;
			}

			.content {
				margin-top: 10px;
				display: flex;

				@media (max-width: 900px) {
					flex-direction: column;
					width: 100%;
				}

				@media (max-width: 500px) {
					flex-direction: column;
					width: 100%;
					margin-top: 0;
				}
				.dashboard-wrapper {
					flex-grow: 1;
					flex-basis: 0;
					.dashboard {
						position: sticky;
						top: 10px;

						> :not(:first-child) {
							margin-top: 30px;
						}
					}
				}

				#receipts {
					flex-grow: 1;
					margin: 10px;
					flex-basis: 0;

					@media (max-width: 900px) {
						margin: 30px 10px 10px 10px;
					}

					.addButton {
						width: 100%;
						text-align: center;
					}
				}
			}
		}
	}

	.card {
		width: 350px;
		margin: 10px;
	}

	.add-card {
		width: 100%;
	}
}
